import jquery from 'jquery';
window.$ = window.jQuery = jquery;

import { config } from './config';
import * as utils from './utils';

export { config, utils };
	
/**
 * 公共模块涉及的函数
 */
//消息
$(document).on("click", "#mark-toggle", () => {
	const markerWrapper = $("#mark-all");
	if (markerWrapper.hasClass("expanded")) {
		markerWrapper.removeClass("expanded");
		markerWrapper.fadeOut();
	} else {

		markerWrapper.addClass("expanded");
		markerWrapper.fadeIn();

		//设置消息全部已读
		$(document).on("click", ".expanded", () => {

			var suburl = config.rooturl + "/api/message/readAll";
			$.ajax({
				type: "GET",
				url: suburl,
				dataType: 'json',
				contentType: "application/json",
				error: function(data) {
					let msg = data.responseText;
					alert(msg);
				},
				success: function(result) {
					var code = result.code;
					if (code == 200) {
						navigationService.goToPage("notification.html");
					}
				}
			});
		});
	}
});

$(document).on("click", "#top-menu-toggle", () => {
	const el = $(".top-menu-nav-mobile-wrapper");
	if (el.hasClass("expanded")) {
		el.slideUp("slow");
		el.removeClass("expanded");
	} else {
		el.slideDown();
		el.addClass("expanded");
	}
});

$(document).on("click", "#menu-toggle", () => {
	const el = $(".right-nav-bar");
	if (el.hasClass("expanded")) {
		el.removeClass("right-0");
		el.removeClass("expanded");
	} else {
		el.addClass("right-0");
		el.addClass("expanded");
	}
});

$(document).on("click", "#notification-toggle", () => {
	const notificationWrapper = $("#notification-wrapper");
	if (notificationWrapper.hasClass("expanded")) {
		notificationWrapper.removeClass("notification-wrapper-expanded");
		notificationWrapper.removeClass("expanded");
	} else {
		notificationWrapper.addClass("notification-wrapper-expanded");
		notificationWrapper.addClass("expanded");
		CloseUserAccountWrapper();
	}
});

$(document).on("click", "#user-account-toggle", () => {
	const notificationWrapper = $("#user-account-wrapper");
	if (notificationWrapper.hasClass("expanded")) {
		notificationWrapper.removeClass("user-account-wrapper-expanded");
		notificationWrapper.removeClass("expanded");
	} else {
		notificationWrapper.addClass("user-account-wrapper-expanded");
		notificationWrapper.addClass("expanded");
		CloseNotificationWrapper();
	}
});

$('html').on("click", function(event) {
	if ($(event.target).closest('#notification-toggle, #user-account-toggle ,#notification-wrapper,#user-account-wrapper').length === 0) {
		CloseNotificationWrapper();
		CloseUserAccountWrapper();
	}
});

export const CloseNotificationWrapper = () => {
	const notificationWrapper = $("#notification-wrapper");
	if (notificationWrapper.hasClass("expanded")) {
		notificationWrapper.removeClass("notification-wrapper-expanded");
		notificationWrapper.removeClass("expanded");
	}
}
export const CloseUserAccountWrapper = () => {
	const notificationWrapper = $("#user-account-wrapper");
	if (notificationWrapper.hasClass("expanded")) {
		notificationWrapper.removeClass("user-account-wrapper-expanded");
		notificationWrapper.removeClass("expanded");
	}
}

//获取当前用户信息
export const getUserInfo = () => {
	var userApi = "/api/adminuser";
	var suburl = config.rooturl + userApi + "/getProfile";
	$.ajax({
		type: "GET",
		url: suburl,
		dataType: 'json',
		contentType: "application/json",
		error: function (data) {
			let code = data.status;
			let msg = data.responseText;
			if (code == 404) {
				console.log(msg);
			} else {
				console.log($.i18n.prop("server.error"));
			}
		},
		success: function (result) {
			var profileDTO = result.data;
			sessionStorage.wavplayLoginAdminUser = JSON.stringify(profileDTO);
			$("#uname").html(profileDTO.realname);
			$("#portrait").attr("src", profileDTO.portrait);
			// $("#portrait").attr("src",rooturl+"/"+profileDTO.portrait);
		}
	});
};

/**
 * 导航选中处理
 */
export function dealtree() {
	var leftTree = sessionStorage.leftTree;
	var accountTree = sessionStorage.accountTree;
	var leftTreeIndex = ".leftTree" + leftTree;
	var accountTreeIndex = ".accountTree" + accountTree;
	// console.log(leftTreeIndex);
	// console.log(accountTreeIndex);
	$(".components li").each(function (i) {
		$(this).removeClass('active'); // 删除其兄弟元素的样式
	});
	$(leftTreeIndex).addClass("active");

	$(".adminaccount li").each(function (i) {
		$(this).removeClass('active'); // 删除其兄弟元素的样式
	});
	$(accountTreeIndex).addClass("active");
}


/**
 * 跳转消息中心
 */
$(document).on("click", "#seaAllNoti", () => {
	navigationService.goToPage("notification.html");
});

// 金额输入格式化
$(document).on('blur', '.rmAmount', function() {
	var amount = $(this).val();
	var b = Number(amount);
	if (b) {
		amount = parseFloat(amount).toFixed(2);
		var fmtAmount = utils.numberFormat(amount, 3, ",");
		$(this).val(fmtAmount);
	} else {
		$(this).val("");
		layer.msg("It's not correct a number")
	}

});

//金额获取焦点清空
$(document).on('focus ', '.rmAmount', function() {
	$(this).val("");
});
